import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import {Link } from 'gatsby'
import { Title, Section, Box, Text } from "../../components/Core";
import { device } from "../../utils";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAmazon, faWordpress } from '@fortawesome/free-brands-svg-icons'
import { faDesktop, faFilter, faMicrophone, faMicrophoneAlt, faSortAmountUpAlt, faCreditCard, faFileInvoice} from '@fortawesome/free-solid-svg-icons'




const Content2 = () => (
  <>
    {/* <!-- Content section --> */}
    <Section className="position-relative" id='compareboxmarginleft'>
      <Container id='indexBlogList'>
        {/* <h2 style={{textAlign: 'center', marginBottom: 50, fontSize: 50, fontWeight: 800, color: '#1c2d35'}}>Nos comparatifs</h2> */}
        <Row className="align-items-center" id='rowlefty'>
       
       <Col> 
       <Link to={'/banque-pro/sas-sasu/'} id='textOnHover'>
         <Box width={360} id='iconBox' bg="light" border="1px solid" borderColor="border" p="20px" style={{marginBottom: 20}} borderRadius={10} className={`d-flex`} css={`min-width: 100%;width: 100%;@media ${device.sm}`}>
    <Box size={55} minWidth={55} borderRadius="50%" color={'red'} fontSize="28px" className="d-flex justify-content-center align-items-center"
    style={{backgroundColor: 'rgb(253 211 49 / 19%)'}} mr={3} >
      <FontAwesomeIcon icon={faDesktop} style={{color: 'rgb(254, 220, 90)'}}/>
    </Box>
    <div><Title variant="card" mb={0}>
   Banque pour SAS/SASU
      </Title>
      <Text><ul style={{marginTop: 10, listStyleType: 'square'}}>
        <li style={{fontSize: 18}}>cartes illimitées</li>
        <li style={{fontSize: 18}}>virements € inclus</li>
        <li style={{fontSize: 18}}>frais hors zone SEPA</li>
        </ul></Text>
    </div>
  </Box></Link>


  <Link to={'/banque-pro/eurl-sarl/'} id='textOnHover'> <Box width={360} id='iconBox' bg="light" border="1px solid" borderColor="border" p="20px" style={{marginBottom: 20}} borderRadius={10} className={`d-flex`} css={`min-width: 100%;width: 100%;@media ${device.sm}`}>
    <Box size={55} minWidth={55} borderRadius="50%" color={'red'} fontSize="28px" className="d-flex justify-content-center align-items-center"
    style={{backgroundColor: 'rgba(240,64,55,0.1)'}} mr={3} >
      <FontAwesomeIcon icon={faWordpress} style={{color: '#e4234f'}}/>
    </Box>
    <div><Title variant="card" mb={0}>
    Banque pour SARL/EURL
      </Title>
      <Text><ul style={{marginTop: 10, listStyleType: 'square'}}>
        <li style={{fontSize: 18}}>frais cartes</li>
        <li style={{fontSize: 18}}>notes de frais</li>
        <li style={{fontSize: 18}}>tarifs virements €</li>
        </ul></Text>
    </div>
  </Box></Link>

  <Link to={'/banque-pro/auto-entrepreneurs/'} id='textOnHover'> <Box width={360} id='iconBox' bg="light" border="1px solid" borderColor="border" p="20px" style={{marginBottom: 20}} borderRadius={10} className={`d-flex`} css={`min-width: 100%;width: 100%;@media ${device.sm}`}>
    <Box size={55} minWidth={55} borderRadius="50%" color={'red'} fontSize="28px" className="d-flex justify-content-center align-items-center"
    style={{backgroundColor: 'rgba(65, 62, 101, 0.1)'}} mr={3} >
      <FontAwesomeIcon icon={faMicrophone}  style={{color: 'rgb(33, 37, 41)'}}/>
    </Box>
    <div><Title variant="card" mb={0}>
    Banque Auto-Entrepreneur
      </Title>
      <Text><ul style={{marginTop: 10, listStyleType: 'square'}}>
        <li style={{fontSize: 18}}>comparer les tarifs</li>
        <li style={{fontSize: 18}}>application iOS / Android</li>
        <li style={{fontSize: 18}}>alertes comptables</li>
        </ul></Text>
    </div>
  </Box></Link>


                  </Col>
       <Col> 
       
       
       <Link to={'/banque-pro/restaurant/'} id='textOnHover'> <Box width={360} id='iconBox' bg="light" border="1px solid" borderColor="border" p="20px" style={{marginBottom: 20}} borderRadius={10} className={`d-flex`} css={`min-width: 100%;width: 100%;@media ${device.sm}`}>
    <Box size={55} minWidth={55} borderRadius="50%" color={'red'} fontSize="28px" className="d-flex justify-content-center align-items-center"
    style={{backgroundColor: 'rgb(242 248 237)'}} mr={3} >
      <FontAwesomeIcon icon={faAmazon}  style={{color: 'rgb(119 191 65)'}}/>
    </Box>
    <div><Title variant="card" mb={0}>
     Banque pour Restaurant
      </Title>
      <Text><ul style={{marginTop: 10, listStyleType: 'square'}}>
        <li style={{fontSize: 18}}>paiement en espèce</li>
        <li style={{fontSize: 18}}>POS intégré</li>
        <li style={{fontSize: 18}}>gestion de factures</li>
        </ul></Text>
    </div>
  </Box></Link>

  <Link to={'/banque-pro/association/'} id='textOnHover'> <Box width={360} id='iconBox' bg="light" border="1px solid" borderColor="border" p="20px" style={{marginBottom: 20}} borderRadius={10} className={`d-flex`} css={`min-width: 100%;width: 100%;@media ${device.sm}`}>
    <Box size={55} minWidth={55} borderRadius="50%" color={'red'} fontSize="28px" className="d-flex justify-content-center align-items-center"
    style={{backgroundColor: 'rgb(253 211 49 / 19%)'}} mr={3} >
      <FontAwesomeIcon icon={faFileInvoice}  style={{color: 'rgb(254, 220, 90)'}}/>
    </Box>
    <div><Title variant="card" mb={0}>
    Banque pour Association
      </Title>
      <Text><ul style={{marginTop: 10, listStyleType: 'square'}}>
        <li style={{fontSize: 18}}>offres promos</li>
        <li style={{fontSize: 18}}>frais cartes</li>
        <li style={{fontSize: 18}}>conditions</li>
        </ul></Text>
    </div>
  </Box></Link>

  <Link to={'/banque-pro/startup/'} id='textOnHover'> <Box width={360} id='iconBox' bg="light" border="1px solid" borderColor="border" p="20px" style={{marginBottom: 20}} borderRadius={10} className={`d-flex`} css={`min-width: 100%;width: 100%;@media ${device.sm}`}>
    <Box size={55} minWidth={55} borderRadius="50%" color={'red'} fontSize="28px" className="d-flex justify-content-center align-items-center"
    style={{backgroundColor: 'rgba(65, 62, 101, 0.1)'}} mr={3} >
      <FontAwesomeIcon icon={faMicrophoneAlt}  style={{color: 'rgb(33, 37, 41)'}}/>
    </Box>
    <div><Title variant="card" mb={0}>
    Banque pour Startup
      </Title>
      <Text><ul style={{marginTop: 10, listStyleType: 'square'}}>
        <li style={{fontSize: 18}}>comptabilité simplifiée</li>
        <li style={{fontSize: 18}}>connection par api</li>
        <li style={{fontSize: 18}}>ouverture rapide</li>
        </ul></Text>
    </div>
  </Box></Link>


     
                  </Col>
       <Col>
       
       
       <Link to={'/banque-pro/sci/'} id='textOnHover'>
         <Box width={360} id='iconBox' bg="light" border="1px solid" borderColor="border" p="20px" style={{marginBottom: 20}} borderRadius={10} className={`d-flex`} css={`min-width: 100%;width: 100%;@media ${device.sm}`}>
    <Box size={55} minWidth={55} borderRadius="50%" color={'red'} fontSize="28px" className="d-flex justify-content-center align-items-center"
    style={{backgroundColor: 'rgb(253 211 49 / 19%)'}} mr={3} >
      <FontAwesomeIcon icon={faFilter} style={{color: 'rgb(254, 220, 90)'}}/>
    </Box>
    <div><Title variant="card" mb={0}>
     Banque pour SCI
      </Title>
      <Text><ul style={{marginTop: 10, listStyleType: 'square'}}>
        <li style={{fontSize: 18}}>frais bancaires</li>
        <li style={{fontSize: 18}}>multi-utilisateurs</li>
        <li style={{fontSize: 18}}>virements gratuis</li>
        </ul></Text>
    </div>
  </Box></Link>

  <Link to={'/banque-pro/depot-capital/'} id='textOnHover'> <Box width={360} id='iconBox' bg="light" border="1px solid" borderColor="border" p="20px" style={{marginBottom: 20}} borderRadius={10} className={`d-flex`} css={`min-width: 100%;width: 100%;@media ${device.sm}`}>
    <Box size={55} minWidth={55} borderRadius="50%" color={'red'} fontSize="28px" className="d-flex justify-content-center align-items-center"
    style={{backgroundColor: 'rgb(242 248 237)'}} mr={3} >
      <FontAwesomeIcon icon={faSortAmountUpAlt}  style={{color: 'rgb(119 191 65)'}}/>
    </Box>
    <div><Title variant="card" mb={0}>
    Dépôt de Capital
      </Title>
      <Text><ul style={{marginTop: 10, listStyleType: 'square'}}>
        <li style={{fontSize: 18}}>cartes illimitées</li>
        <li style={{fontSize: 18}}>virements € inclus</li>
        <li style={{fontSize: 18}}>frais hors zone SEPA</li>
        </ul></Text>
    </div>
  </Box></Link>

  <Link to={'/banque-pro/carte-corporate/'} id='textOnHover'> <Box width={360} id='iconBox' bg="light" border="1px solid" borderColor="border" p="20px" style={{marginBottom: 20}} borderRadius={10} className={`d-flex`} css={`min-width: 100%;width: 100%;@media ${device.sm}`}>
    <Box size={55} minWidth={55} borderRadius="50%" color={'red'} fontSize="28px" className="d-flex justify-content-center align-items-center"
    style={{backgroundColor: 'rgba(240,64,55,0.1)'}} mr={3} >
      <FontAwesomeIcon icon={faCreditCard}  style={{color: '#e4234f'}}/>
    </Box>
    <div><Title variant="card" mb={0}>
    Carte Corporate
      </Title>
      <Text><ul style={{marginTop: 10, listStyleType: 'square'}}>
        <li style={{fontSize: 18}}>comptabilité+</li>
        <li style={{fontSize: 18}}>plafond de blocage</li>
        <li style={{fontSize: 18}}>tableau de bord</li>
        </ul></Text>
    </div>
  </Box></Link>



    
                  </Col>
               
          
        
        </Row>
      </Container>
    </Section>
  </>
);

export default Content2;
