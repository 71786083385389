import React from "react";
import {Helmet} from "react-helmet";
import PageWrapper from "../../components/PageWrapper";
import Header from "../../components/HeaderPro";
import Footer from "../../components/FooterPro";
import Hero from "../../sections/landing2/HeroPro";
import { Post } from "../../components/Core";
import {StaticImage} from 'gatsby-plugin-image';
import Content1 from "../../sections/landing2/Content1Pro";


const LandingPage2 = () => {
  return (
    <>
      <PageWrapper footerDark>
      <Helmet>
                <meta charSet="utf-8" />
                <title>BankBank - Comparateur de banques personnelles et banques pro</title>
                <meta name="description" content="
               Are you starting a business? Discover new business ideas, use marketing tips to get more clients, and raise funds from VCs and angel investors with Startupmag." />
        </Helmet>

        <Header />
        <Hero />
        <Content1/>
        


     <Post style={{marginBottom: 100}}>
     <h2>Choisir la meilleure banque pour votre compte pro</h2>
     <p>
Dans le passé, la banque pro était intimidante et très opaque aux niveaux de tarifs. Ouvrir un compte bancaire pro nécessitait de montrer un prévisionnel et obligeait à des rendez-vous stressant avec un représentant bancaire qui ne comprenait pas forcément votre activité.
</p>


<div style={{maxWidth: 800, margin: 'auto'}}>
			<StaticImage
		  	src='../../images/banque-pro.png'
			width={800}
			alt='meilleure banque pro'
			placeholder='tracedSVG'
			id='pickky'
			/>
			</div>
<p>
Pour les startup et les nouvelles entreprises, cet échange était encore plus pénible lorsque l’activité n’était pas encore bien déterminée. Détenir un compte pro était légalement obligatoire mais la banque pouvait décliner votre ouverture de compte simplement car elle ne croyait pas en votre projet d’entreprise.
</p><p>
Avant, la banque pro c’était compliqué ! Mais ce n’est désormais plus le cas !
</p><p>
Avec l’arrivée des banques en ligne ainsi que les néobanques, l’environnement des comptes pour professionnels a connu une révolution. L’ouverture du compte est plus simple et plus rapide, les tarifs sont plus clairs et les services sont plus adaptés aux nouveaux besoins des entreprises.
</p><p>
La difficulté réside aujourd’hui dans le trop de choix. Beaucoup d’offres et beaucoup d’options rendent la décision difficile. On passe d’un environnement bancaire où A) on choisissait la première banque à notre disposition car le processus d’ouverture est trop long à B) on choisit la première banque à notre disposition car on a pas le temps de comparer toutes les possibilités à notre disposition.
</p>
<h2>Quelle banque pro choisir ?</h2>
<p>
Le statut de votre entreprise est le premier élément à définir avant de pouvoir comparer les offres des comptes bancaires pro. Êtes-vous un autoentrepreneur ou avez-vous une société (EURL, SARL, SAS, SA) ?
</p><p>
La loi différencie bien ces 2 cadres et tentent de rendre la vie bancaire d’un autoentrepreneur un peu plus simple et plus économique.
</p>
<h3>La meilleure banque pro pour autoentrepreneur ou micro-entrepreneur ?</h3>
<p>
Depuis le 1 janvier 2015, l’Etat a obligé les autoentrepreneurs et micro-entrepreneurs à dissocier leurs finances personnelles de celles liées à leur emploi pour réduire la fraude. Pour cela, un compte non-professionnel est tout à fait accepté. En effet, la Fédération des Auto Entrepreneurs a rappelé aux banque le 22 juillet 2017 lors d’un communiqué officiel que les autoentrepreneurs ont bien le droit d’utiliser un compte bancaire non-professionnel pour leur activité.
</p>
[Table]
<p>
L’article L133-6-8-4 du code de la sécurité sociale (Section 2 ter : Règlement simplifié des cotisations et contributions des travailleurs indépendants ― Régime micro-social) :  « Le travailleur indépendant […] est tenu, au plus tard douze mois après la déclaration de la création de son entreprise, de dédier un compte ouvert dans un des établissements mentionnés à l’article L. 123-24 du code de commerce à l’exercice de l’ensemble des transactions financières liées à son activité professionnelle. »
</p>
<p>
Ce compte dédié que vous devrez ouvrir sera utilisé pour votre activité d’auto-entrepreneur uniquement pour encaisser les recettes et y prélever les dépenses liées à l’activité.
</p><p>
Si vous prévoyez de recevoir des virements ou des chèques à votre nom personnel, pas de souci pour avoir un compte bancaire non-professionnel. En effet, le seul inconvénient avec ce type de compte est que vous ne pourrez pas recevoir de chèque sous un nom commercial de l’activité d’auto-entrepreneur.
</p>
<h3>
La meilleure banque pro pour les sociétés ?</h3>
<p>
Le compte professionnel est un passage obligatoire pour les créateurs de société, que le statut juridique soit notamment EURL, SA, SAS, SARL puisqu’ils doivent y déposer le capital social de l’entreprise et leur permettre de s’enregistrer au registre du Commerce et des Sociétés. A noter que Manager.one est la seule banque pro à gérer les entreprises ayant un actionnaire personne morale (ex filiale d’une holding) et à gérer les sociétés à capital variable ou les augmentations de capital.
</p>
[Table]
<p>
C’est obligatoire mais c’est aussi utile. Un compte professionnel permet d’effectuer les dépenses liées à son activité ou des prélèvements pour se rémunérer, d’encaisser des factures. Elle permet aussi d’obtenir un prêt professionnel destiné au bon fonctionnement de l’entreprise.
</p>
<h3>Frais bancaires générés par l’ouverture d’un compte professionnel</h3>
<p>
À l’image des comptes personnels, les comptes professionnels engendrent des frais qu’il est utile de connaître pour comparer les banques. Parmi ces frais :
</p>
<ul>
  <li>Tenue de compte</li>
  <li>Frais de virement</li>
  <li>Tarif pour d’un dépôt de chèque</li>
  <li>Plafond du nombre de cartes bancaires physique</li>
  <li>Plafond du nombre de carte virtuelle</li>
  <li>Terminaux de paiement</li>
  <li>Commission sur chaque transaction</li>
</ul>


<h3>Néobanque, banque en ligne ou une banque de réseau traditionnelle pour votre compte bancaire pro ?</h3>
<h4>Les avantages et inconvénients majeurs de la néobanque</h4>
<ul>
  <li>Ouverture de compte rapide</li>
  <li>Compte flexible qui peut grandir avec votre entreprise</li>
  <li>Tarifs attractifs</li>
  <li>Interface et application mobile performante</li>
  <li>Problème – les services liés au liquide ainsi que les chèques bancaires peuvent être très cher.</li>

</ul>



<h4>Banques en ligne – avantages et inconvénients</h4>
<ul>
  <li>Moins chères voire gratuites pour la plupart des opérations</li>
  <li>Vous bénéficiez d’une offre de bienvenue qui peut booster votre départ</li>
  <li>La carte bancaire est gratuite la plupart du temps</li>
  <li>Vous pouvez conserver votre compte actuel et ne pas changer pas de banque</li>
  <li>Si la banque en ligne ne propose pas de dépôt chez des banques de réseau partenaire, l’envoi des chèque à encaisser se fait par courrier recommandé (exemple Boursorama)</li>
  <li>Problème : pas de nom d’enseigne ou commercial pour votre activité : elle doit être en votre nom propre.</li>
   <li>Problème : l’ouverture du compte est parfois conditionnée à un minimum de revenus mensuels ou d’épargne (Fortuneo, BforBank, Monabanq, Hellobank)</li>
</ul>


<h3>Le bon et le moins bon des banques de réseau</h3>
<p>
Proximité : plus facile d’y déposer des chèques (mais un compte Hellobank vous permet de déposer des chèques dans les agences BNP).</p>
<p>
Possibilité d’ouvrir un compte professionnel, qui vous coûtera bien plus cher qu’un compte particulier et surtout un compte en banque en ligne.</p>
<p>
Problème : les horaires d’ouverture sont souvent limitées notamment le soir et le week-end.</p>
<p>
Problème : Les banques de réseau proposent généralement des offres très chères et lourdes en options inutiles</p>

     </Post>


        <Footer/>
      </PageWrapper>
    </>
  );
};
export default LandingPage2;
